import { darken, getContrast } from 'polished'
import { ActionsBlockProps } from '@segment/consent-manager/types/types'

import { Button, Flex } from '@lib/UIComponents'
import colors from '@lib/UIComponents/Theme/colors'

const style = {
  button: ({ backgroundColor }: Record<string, string>) => ({
    minWidth: '150px',
    justifyContent: 'center',
    backgroundColor,
    borderColor: backgroundColor,
    '&:hover': {
      backgroundColor: darken(0.1, backgroundColor),
      borderColor: darken(0.1, backgroundColor)
    },
    '&:active': {
      backgroundColor: darken(0.15, backgroundColor),
      borderColor: darken(0.15, backgroundColor)
    }
  })
}

const ConsentBannerActions =
  (primaryColor: string) =>
  ({ acceptAll, denyAll }: ActionsBlockProps) => {
    const WCAG_GRAPHIC_USER_INTERFACE_CONTRAST_RATIO = 3
    const black = '#000000'
    const white = '#FFFFFF'

    const blackContrastRatio = getContrast(primaryColor, black)

    const hasContrastWithBlack =
      blackContrastRatio > WCAG_GRAPHIC_USER_INTERFACE_CONTRAST_RATIO

    const allowButtonStyle = {
      background: hasContrastWithBlack ? primaryColor : colors.primary
    }

    return (
      <Flex sx={{ gap: '10px', justifyContent: 'space-around' }}>
        <Button
          sx={style.button({
            backgroundColor: allowButtonStyle.background
          })}
          onClick={() => acceptAll()}
        >
          Allow all
        </Button>
        <Button
          sx={{
            ...style.button({
              backgroundColor: white
            })
          }}
          variant="default"
          onClick={() => denyAll()}
        >
          Deny all
        </Button>
      </Flex>
    )
  }

export default ConsentBannerActions
