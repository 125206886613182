import { pathOr } from 'ramda'

import { usePublisherContext } from '@concepts/Publisher/store/context'
import { NavHelloBar } from 'src/generated/graphql'
import helloBarAnalytics from '@lib/gtm/events/helloBar'
import segmentAnalytics from '@lib/segment/analytics'

type ReturnProps = {
  applyAnalytics: () => void
  active: NavHelloBar['active']
  path: NavHelloBar['path']
  text: NavHelloBar['text']
}

const useHelloBar = (): ReturnProps => {
  const helloBar = pathOr<Partial<NavHelloBar>>(
    {},
    ['layout', 'navHelloBar'],
    usePublisherContext()
  )

  const applyAnalytics = () => {
    const { path, text } = helloBar
    const currentUrl = window.location.href
    const destinationUrl = window.location.origin + path

    helloBarAnalytics.trackLink(currentUrl, destinationUrl, text)
    segmentAnalytics.trackHelloBar(currentUrl, destinationUrl, text)
  }

  return {
    applyAnalytics,
    active: helloBar.active,
    path: helloBar.path,
    text: helloBar.text
  }
}

export { useHelloBar }
