import { pathOr } from 'ramda'
import { ConsentManager } from '@segment/consent-manager'

import { useTheme } from '@lib/UIComponents/Theme/hooks'
import colors from '@lib/UIComponents/Theme/colors'

import ConsentBannerActions from './ConsentBannerActions'
import { Link } from '@lib/UIComponents'

type Props = {
  writeKey: string
  visible: boolean
}

const SegmentConsentBanner: React.FC<Props> = ({ writeKey, visible }) => {
  const theme = useTheme()

  const bannerContent = (
    <span>
      We use cookies during your browsing experience. Learn more about our
      updated{' '}
      <Link
        href="/privacy"
        target="_blank"
        sx={{
          textDecoration: 'underline',
          color: 'link.0'
        }}
      >
        Privacy Policy.
      </Link>
    </span>
  )
  const bannerSubContent = 'You can change your preferences at any time.'
  const preferencesDialogTitle = 'Data Collection Preferences'
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.'
  const cancelDialogTitle = 'Are you sure you want to cancel?'
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Data Collection Policy.'

  return (
    <ConsentManager
      writeKey={writeKey}
      bannerContent={bannerContent}
      bannerSubContent={bannerSubContent}
      preferencesDialogTitle={preferencesDialogTitle}
      preferencesDialogContent={preferencesDialogContent}
      cancelDialogTitle={cancelDialogTitle}
      cancelDialogContent={cancelDialogContent}
      shouldRequireConsent={() => visible}
      defaultDestinationBehavior="ask"
      bannerActionsBlock={ConsentBannerActions(
        pathOr(colors.primary, ['colors', 'primary'], theme)
      )}
      bannerBackgroundColor={theme.colors.black}
    />
  )
}

export default SegmentConsentBanner
