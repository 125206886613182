import HelloBar from '@molecules/HelloBar/HelloBar'
import NavBar from '@molecules/NavBar/NavBar'
import EmailBanner from '@molecules/EmailBanner/EmailBanner'
import CookieBanner from '@molecules/CookieBanner/CookieBanner'
import { Box } from '@lib/UIComponents'

type Props = {
  useHelloBar?: boolean
}

const Header = ({ useHelloBar }: Props) => {
  return (
    <Box
      as="header"
      id="header"
      sx={{
        zIndex: 'header',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0
      }}
    >
      <CookieBanner />
      <EmailBanner />
      {useHelloBar && <HelloBar />}
      <NavBar />
    </Box>
  )
}

export default Header
