import SegmentConsentBanner from '@lib/segment/views/SegmentConsentBanner'

import { usePublisherContext } from '@concepts/Publisher/store/context'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import { getSegmentConfig } from '@lib/segment/utils'
import { SEGMENT_CONSENT_BANNER_ENABLED } from '@concepts/FeatureFlag/utils/constants'

const CookieBanner = () => {
  const publisher = usePublisherContext()
  const featureFlag = useFeatureFlag()

  const { key } = getSegmentConfig(
    publisher?.integrationsSetting?.segmentFacebookTrack as boolean,
    publisher?.integrationsSetting?.segmentClientSideKey as string
  )

  const isConsentBannerEnabled = featureFlag.isEnabled(
    SEGMENT_CONSENT_BANNER_ENABLED,
    'publisher'
  )

  return (
    <SegmentConsentBanner
      writeKey={key as string}
      visible={isConsentBannerEnabled}
    />
  )
}

export default CookieBanner
